export const environment = {
  production: false,
  targetAPI: 'https://customer-middleware.afrikpay.com/',
  agentid : '3999415050546916',
  agentpwd : '3999@6916$',
  apikey : '1cde9432d7b60a35d17dc5cb71893a6a',
  url : 'https://socec.afrikpay.com',
  id : 'socec.afrikpay.com',
  accepturl : 'https://socec.afrikpay.com/payment/web/success',
  cancelurl : 'https://socec.afrikpay.com/payment/web/failed',
  printurl : "http://invoice.afrikpay.com/print/",
  storage : 'home/oss'
};
